import React, { useContext, useEffect, useState } from 'react';
import { Tab, Modal, Button } from "semantic-ui-react";
import Loading from '../../components/Loading/ProgressLoading';
import WebOrderRow from '../../components/WebOrderRow';
import EmptyMessage from './emptyMessage';
import { Context as orderManagementContext } from '../../context/orderManagement';
import './index.css';
import Sound from "react-sound";
import newOrder from '../../assets/sounds/new-order.mp3';

const Table = (props) => {
    const {
        orders,
        activeDropDown,
        setActiveDropDown,
        orderStatus,
        emptyTitle,
        emptyMessage,
    } = props;
    const { orderModal, hideOrderModal } = useContext(orderManagementContext);
    const [audioStatus, setAudioStatus] = useState('STOPPED');

    useEffect(() => {
        if (orderStatus === '*' && orders?.length > 0) {
            if (orders.find((order) => order.orderStatus === 'Pending')) {
                setAudioStatus('PLAYING');
            } else {
                setAudioStatus('STOPPED');
            }
        }
    }, [orders]);

    if (orders === null){
        return <div className="web-order-table-loading-container">
            <Loading/>
        </div>
    }

    const ordersFiltered = orderStatus !== '*' ? orders.filter((order) => orderStatus.includes(order.orderStatus)) : orders;
    
    if(ordersFiltered.length < 1){
        return (
        <EmptyMessage
            title={emptyTitle}
            message={emptyMessage}
        />)
    }

    return (<>
        <Modal open={orderModal.show}>
            <Modal.Header>{orderModal.title}</Modal.Header>
            <Modal.Content>{orderModal.message}</Modal.Content>
            <Modal.Actions>
                <Button onClick={hideOrderModal}>OK</Button>
            </Modal.Actions>
        </Modal>
        {audioStatus === "PLAYING" ? (
            <Sound
                url={newOrder}
                playStatus={audioStatus}
                loop={true}
            />
        ) : null}
        <Tab.Pane>
            <ul className="web-order-table">
                {ordersFiltered.map((order, index) => {
                    return(
                     <WebOrderRow
                        order={order}
                        key={order._id}
                        activeDropDown={activeDropDown}
                        index={index}
                        setActiveDropDown={setActiveDropDown}
                    />
                    );
                })}
            </ul>
        </Tab.Pane>   
        </>
    )
}

export default Table;