import React, { useState, useRef, useEffect } from "react";
import Timer from "easytimer.js";
import { CircularProgressbarWithChildren, buildStyles} from "react-circular-progressbar";
import { getPercentage, timeDifference } from "../../components/Helpers";
import "./index.css";
import useSound from 'use-sound';
import alert from '../../assets/sounds/alert.mp3';



const PRing = ({orderEta, eta, setEta}) => {
  const [playAlertSound] = useSound(alert);
  const [didAlert,setDidAlert] = useState(false);
  const timer = useRef(new Timer()).current;
  const timeDiff = timeDifference(orderEta);
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if(percentage <= 25 && !didAlert){
      playAlertSound();
      setDidAlert(true);
    }
  },[percentage,didAlert,playAlertSound])

  useEffect(() => {
    if (timeDiff) {
      timer.start({
        countdown: true,
        startValues: { minutes: timeDiff.min + 2 },
      });
    }
    if(!timeDiff) {
      setEta(0);
    }

    const timerEvent = () => {
      const min = timer.getTimeValues().minutes;
      setEta(min);
      setPercentage(getPercentage(min,timeDiff.min))
    };

    timer.addEventListener("minutesUpdated", timerEvent);

    return () => timer.removeEventListener("minutesUpdated", timerEvent);
    // eslint-disable-next-line
  }, []);
  
  const PRingStyle = buildStyles({
    pathColor: percentage < 30 ? "#8f0f27" : "#00b800",
    trailColor: percentage < 1 ? "#8f0f27" : "silver"
  });

  return (
    <div className="timer">
      {eta !== null ?<CircularProgressbarWithChildren 
        value={percentage}
        styles={PRingStyle}>
        <p id="eta-text">{eta}</p>
      </CircularProgressbarWithChildren>:""}
    </div>
  );
};

export default PRing;
