import React, { useState, useContext, useEffect } from "react";
import { useSubscription, useMutation, useLazyQuery } from "@apollo/react-hooks";

//3red party
import * as firebase from 'firebase';
import Sound from "react-sound";

import newOrder from '../assets/sounds/new-order.mp3';
//others
import { errorType, parseError } from "../components/Helpers";
import { status } from "../constants/orderStatus";
import { Context as notificationContext } from "./notifications";
import {
  GET_ORDERS,
  NEW_ORDER,
  CHANGE_ORDER_STATUS,
  ORDER_STATUS_CHANGE,
  GET_STORE,
  SWITCH_TO_MASHKOR,
} from "../components/GraphQL";
import { nanoid } from "nanoid";
import { status as orderStatus } from '../constants/orderStatus';
const staffId = nanoid(10);



export const Context = React.createContext();

export const Provider = (props) => {
  const currentUser = firebase.auth().currentUser;

  const notification = useContext(notificationContext);
  const [changeStatus] = useMutation(CHANGE_ORDER_STATUS);
  const [changeServiceToMashkor] = useMutation(SWITCH_TO_MASHKOR);
  const [allOrders, setAllOrders] = useState(null);
  const [pendingOrdersSize, setPendingOrdersSize] = useState(0);
  const [deliveryVendorList, setDeliveryVendorList] = useState([]);
  const [viewOrder, setViewOrder] = useState(null);
  const [storeData, setStoreData] = useState(null);
  const [orderModal, setOrderModal] = useState({
    show: false,
    message: '',
  })

  const hideOrderModal = () => {
    setOrderModal({
      show: false,
      message: '',
      title: '',
    })
  }

  const handledOrderSoundEvent = () => {
    try{
      if(notification.didUserInteract){
        if(pendingOrdersSize > 0 && notification.orderSoundStatus === Sound.status.STOPPED){
          notification.actions.playOrderSound()
        }else if
        (pendingOrdersSize <= 0 && notification.orderSoundStatus === Sound.status.PLAYING){
          notification.actions.stopOrderSound();
        }
      }
    }catch(error){
    }
  }

  useEffect(() => {
    notification.actions.localNotify({
        title: 'Sound notification',
        message: 'To activate the sound notification, click anywhere on the screen then go to the browser tab and right click --> unmute site. ',
        soundType: "notification",
        type: "warning",
    });
  }, []);

  useEffect(() => {
    handledOrderSoundEvent();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pendingOrdersSize, notification.didUserInteract]);

  const [multipleOutlet, setMultipleOutlet] = useState(false)
  const [selectedOutlets, setSelectedOutlets] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);

  const [getStoreOrders] = useLazyQuery(GET_ORDERS, {
    fetchPolicy: "no-cache",
    variables: { status: [status.pending, status.accepted, status.ready, status.enRoute], multipleOutlet },
    onCompleted: (data) => {
      if (data) {
        if (data.getSellerOrders.length > 0) {
          const _allOrders = data.getSellerOrders;
          setUnfilteredData(_allOrders);
          setAllOrders(_allOrders);
          setPendingOrdersSize(_allOrders.filter(order => order.orderStatus === status.pending).length);
        } else {
          setAllOrders([]);
        }
      }
    },
  });

  const handleRefresh = () => {
    if (unfilteredData && selectedOutlets && multipleOutlet) {
      setAllOrders(unfilteredData.filter(x => selectedOutlets.includes(x.storeId) || x.storeId === storeData._id))
    }
  }

  useEffect(() => {
    handleRefresh();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOutlets, multipleOutlet, unfilteredData]);


  const [refresh, setRefresh] = useState(null);
  useEffect(() => {
    if(refresh) {
      getStoreOrders();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  const [getStoreData] = useLazyQuery(GET_STORE, {
    onCompleted: (data) => {
      if (data.getStore) {
        setStoreData(data.getStore);
        if(data.getStore.mainOutlet) {
          setMultipleOutlet(true);
        }
        setDeliveryVendorList(data.getStore?.deliveryVendors?[...data.getStore?.deliveryVendors]:[]);
      }
    }
  });

  useEffect(() => {
    const listener = firebase.auth().onAuthStateChanged((authUser) => {
      if(authUser){
        getStoreData();
        getStoreOrders();
      } else {      
        setAllOrders(null);
        setPendingOrdersSize(0);
        setDeliveryVendorList([]);
      }
    })

    return () => {
      listener();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //listen to new orders
  useSubscription(NEW_ORDER, {
    variables: { storeId: currentUser ? currentUser.uid : '' },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (data.newOrder && currentUser) {
        const newOrder = data.newOrder;

        if(storeData?.mainOutlet) { // store is main outlet
            // check if new order id is in selected outlet
            if(!selectedOutlets.includes(data.newOrder.storeId) && data.newOrder.storeId !== storeData._id) {
              return ;
            }
        }

        notification.actions.localNotify({
          id: newOrder._id,
          title: "New incoming order!",
          message: `You have a new order to accept! #${newOrder.orderNumber}.`,
          soundType: null,
        });
        setAllOrders(state => [newOrder, ...state]);
        setPendingOrdersSize(state => state+1)
      }
    },
  });

  //listen for status change
  useSubscription(ORDER_STATUS_CHANGE, {
    variables: { customerId: currentUser ? currentUser.uid: '', staffId: staffId },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (data.orderStatusChanged && currentUser) {
        let title, message, type;
        const {cancelled, completed, incomplete, declined, accepted, ready, enRoute} = status;
        const _order = data.orderStatusChanged;
        const newStatus = _order.orderStatus;
        const orderId = _order._id;
        const orderNum = _order.orderNumber;
        if ([cancelled, completed, incomplete, declined].includes(newStatus)) {
          setAllOrders(state => state.filter(order => {
              if(order._id === orderId){
                if (newStatus === cancelled) {
                  title = "Order cancellation!";
                  message = `Order #${orderNum} has been Cancelled by the customer.`;
                  type = "danger";
                } else {
                    title = `Order ${newStatus}!`;
                    type = "success";
                    if(order.deliveryInfo?.deliveryOrderStatus?.length < _order.deliveryInfo?.deliveryOrderStatus?.length){
                      message = `Order #${orderNum} has been taken care of by the delivery vendor.`;  
                    }else{
                      message = `Order #${orderNum} has been taken care of by your team.`;
                    }
                }
              }
            return order._id !== orderId
          }));
        }

        if([accepted, ready, enRoute].includes(newStatus)){
          title = `Order ${newStatus}!`;
          type = "success";
          setAllOrders(state => state.map(order => {
            if(order._id === orderId){
              if(order.deliveryInfo?.deliveryOrderStatus?.length < _order.deliveryInfo?.deliveryOrderStatus?.length){
                message = `Order #${orderNum} has been taken care of by the delivery vendor.`;  
              }else{
                message = `Order #${orderNum} has been taken care of by your team.`;
              }
              return {
                ...order,
                ..._order
              };
            }
            return order;
          }));
        }
        
        notification.actions.localNotify({
          title,
          message,
          type,
          soundType: "alert",
        });
        if (viewOrder && viewOrder._id === data.orderStatusChanged._id) {
          setViewOrder(null);
        }
      }
    },
  });

  const onChangeOrderStatus = async (event) => {
    const this1 = event.target;
    const eventName = this1.name;
    const orderNum = parseInt(this1.dataset.orderNum);
    const orderId = this1.dataset.orderId;
    const eta = this1.dataset.eta;
    const { 
      accepted,
      declined,
      ready,
      completed,
      incomplete,
      enRoute,
      changeToMashkor,
    } = orderStatus;
    // eslint-disable-next-line
    switch (eventName) {
      case accepted:
        return onAccepted(orderNum, orderId, eta);
      case declined:
        return onDecline(orderNum, orderId);
      case ready:
        return onReady(orderId);
      case completed:
        return onCompleted(orderId);
      case incomplete:
        return onInCompleted(orderId);
      case enRoute:
        return onenRoute(orderId);
      case changeToMashkor:
        return changeService(orderId)
    }
  };

  const changeService = async(orderId) => {
    try {
      await changeServiceToMashkor({ variables: { orderId, staffId }}).then(x => {
        const { _id, orderType, vendor } = x.data.switchSelfToMashkor;
        const changeState = (state) => state.map(order => {
          if(order._id === _id){
            return {
              ...order,
              orderType: orderType,
              vendor: vendor,
            }
          }
          return order;
        })
        setAllOrders(changeState);
      }).catch(error => {
        notification.actions.localNotify({
          title: "Error!",
          message: `Something went wrong: ${JSON.stringify(error?.graphQLErrors[0]?.message)} `,
          soundType: "alert",
          type: "danger",
        });
      })
    }catch(error){
      notification.actions.localNotify({
        title: "Error!",
        message: `Something went wrong: ${JSON.stringify(error?.graphQLErrors[0]?.message)} `,
        soundType: "alert",
        type: "danger",
      });
    }
  }

  const onAccepted = async (orderNum, orderId, eta) => {
    try {
      notification.actions.dismissLocalNotify(orderId);
      //Get Vendor Name
      const selectedOrder = allOrders.find(order => order._id.toString() === orderId)
      const OrderStatusInput = {
        orderId: orderId,
        status: status.accepted,
        eta: eta,
        vendor:selectedOrder?selectedOrder.vendor:undefined,
      };

      await changeStatus({ variables: { OrderStatusInput, staffId } })
        .then((res) => {
          moveAcceptedOrder(orderId, eta);
        })
        .catch((error) => {
          if(parseError(error)){
            setOrderModal({
              show: true,
              message: parseError(error),
              title: "Order No #" + orderNum 
            })
            return;
          }
          const errorMessage = error.message;
          let title;
          let message;
          let soundType;
          let type;

          if (errorType(errorMessage) === "cancelled") {
            title = "Order cancelled!";
            message = `Order #${orderNum} was been canceled by the customer.`;
            soundType = "alert";
            type = "danger";
            setAllOrders(state => state.filter(
              (order) => order._id !== orderId
            ));
          }

          if (errorType(errorMessage) === "Already changed") {
            title = "Order handled!";
            message =
              "It looks like your team has already Accepted this order.";
            soundType = "notification";
            type = "success";
          } else {
            title = "Error!";
            message = `Order cant be ${OrderStatusInput.status}.`;
            soundType = "alert";
            type = "danger";
          }

          notification.actions.localNotify({
            title,
            message,
            type,
            soundType,
          });
        });
    } catch (error) {
      notification.actions.localNotify({
        title: "Error!",
        message: `Something went wrong: ${JSON.stringify(error)} `,
        soundType: "alert",
        type: "danger",
      });
    }
  };

  const moveAcceptedOrder = (orderId, eta) => {
    const etaTillFoodIsReady = new Date(
      new Date().getTime() + parseInt(eta) * 60000
    );
   
    const changeState = (state) => state.map(order => {
      if(order._id === orderId){
        return {
          ...order,
          orderStatus: status.accepted,
          timeStampEta: etaTillFoodIsReady
        }
      }
      return order;
    })
    setAllOrders(changeState);
    setPendingOrdersSize(prevState => prevState - 1);
  };

  const onReady = async (orderId) => {
    try {
      const OrderStatusInput = {
        orderId: orderId,
        status: status.ready,
      };

      await changeStatus({ variables: { OrderStatusInput, staffId } })
        .then((res) => {
          moveReadyOrder(orderId);
        })
        .catch((error) => {
          const errorMessage = error.message;
          let title;
          let message;
          let soundType;
          let type;

          if (errorType(errorMessage) === "Already changed") {
            title = "Order handled!";
            message =
              "It looks like your team has already Accepted this order.";
            soundType = "notification";
            type = "success";
          } else {
            title = "Error!";
            message = `Order cant be ${OrderStatusInput.status}.`;
            soundType = "alert";
            type = "danger";
          }

          notification.actions.localNotify({
            title,
            message,
            type,
            soundType,
          });
        });
    } catch (error) {
      notification.actions.localNotify({
        title: "Error!",
        message: `Something went wrong: ${JSON.stringify(error)} `,
        soundType: "alert",
        type: "danger",
      });
    }
  };


  const moveReadyOrder = (orderID) => {
    const changeState = (state) => state.map(order => {
      if(order._id === orderID){
        return {
          ...order,
          orderStatus :status.ready,
        };
      }
      return order;
    });
    setAllOrders(changeState);
    // setPendingOrders(changeState);
  };

  const moveenRouteOrder = (orderID) => {
    const changeState = (state) => state.map(order => {
      if(order._id === orderID){
        return {
          ...order,
          orderStatus :status.enRoute,
        };
      }
      return order;
    });
    setAllOrders(changeState)
   
  };

  const onenRoute = async (orderId) => {
    try {
      const OrderStatusInput = {
        orderId: orderId,
        status: status.enRoute,
      };

      await changeStatus({ variables: { OrderStatusInput, staffId } })
        .then((res) => {
          moveenRouteOrder(orderId);
        })
        .catch((error) => {
          const errorMessage = error.message;
          let title;
          let message;
          let soundType;
          let type;

          if (errorType(errorMessage) === "Already changed") {
            title = "Order handled!";
            message =
              "It looks like your team has already Accepted this order.";
            soundType = "notification";
            type = "success";
          } else {
            title = "Error!";
            message = `Order cant be ${OrderStatusInput.status}.`;
            soundType = "alert";
            type = "danger";
          }

          notification.actions.localNotify({
            title,
            message,
            type,
            soundType,
          });
        });
    } catch (error) {
      notification.actions.localNotify({
        title: "Error!",
        message: `Something went wrong: ${JSON.stringify(error)} `,
        soundType: "alert",
        type: "danger",
      });
    }
  };

  const onDecline = async (orderNum, orderId) => {
    try {
      notification.actions.dismissLocalNotify(orderId);
      const OrderStatusInput = {
        orderId: orderId,
        status: status.declined,
      };

      await changeStatus({ variables: { OrderStatusInput, staffId } })
        .then((res) => {
          moveDeclineOrder(orderId);
        })
        .catch((error) => {
          const errorMessage = error.message;
          let title;
          let message;
          let soundType;
          let type;

          if (errorType(errorMessage) === "cancelled") {
            title = "Order cancelled!";
            message = `Order #${orderNum} was been canceled by the customer.`;
            soundType = "alert";
            type = "danger";
          }

          if (errorType(errorMessage) === "Already changed") {
            title = "Order handled!";
            message =
              "It looks like your team has already Declined this order.";
            soundType = "notification";
            type = "success";
          } else {
            title = "Error!";
            message = `Order cant be ${OrderStatusInput.status}.`;
            soundType = "alert";
            type = "danger";
          }

          notification.actions.localNotify({
            title,
            message,
            type,
            soundType,
          });
        });
    } catch (error) {
      notification.actions.localNotify({
        title: "Error!",
        message: `Something went wrong: ${JSON.stringify(error)} `,
        soundType: "alert",
        type: "danger",
      });
    }
  };
  
  const moveDeclineOrder = (orderId) => {
    const changeState = state => state.filter((order) => {
      return order._id !== orderId
    });

    setAllOrders(changeState);
    setPendingOrdersSize(prevState => prevState - 1);

    if (viewOrder && viewOrder._id === orderId) {
      setViewOrder(null);
    }
  };

  const onCompleted = async (orderId) => {
    try {
      const OrderStatusInput = {
        orderId: orderId,
        status: status.completed,
      };

      await changeStatus({ variables: { OrderStatusInput, staffId } })
        .then((res) => {
          moveCompletedOrder(orderId);
        })
        .catch((error) => {
          const errorMessage = error.message;
          let title;
          let message;
          let soundType;
          let type;

          if (errorType(errorMessage) === "Already changed") {
            title = "Order handled!";
            message =
              "It looks like your team has already Accepted this order.";
            soundType = "notification";
            type = "success";
          } else {
            title = "Error!";
            message = `Order cant be ${OrderStatusInput.status}.`;
            soundType = "alert";
            type = "danger";
          }

          notification.actions.localNotify({
            title,
            message,
            type,
            soundType,
          });
        });
    } catch (error) {
      notification.actions.localNotify({
        title: "Error!",
        message: `Something went wrong: ${JSON.stringify(error)} `,
        soundType: "alert",
        type: "danger",
      });
    }
  };

  const moveCompletedOrder = (orderId) => {
    const changeState = state => state.filter((order) => {
      return order._id !== orderId
    });
    //Fixed SetState Inconsistencies
    setAllOrders(changeState);
    if (viewOrder && viewOrder._id === orderId) {
      setViewOrder(null);
    }
    if (viewOrder && viewOrder._id === orderId) {
      setViewOrder(null);
    }
  };

  const onInCompleted = async (orderId) => {
    try {
      const OrderStatusInput = {
        orderId: orderId,
        status: status.incomplete,
      };

      await changeStatus({ variables: { OrderStatusInput, staffId } })
        .then((res) => {
          moveInCompletedOrder(orderId);
        })
        .catch((error) => {
          const errorMessage = error.message;
          let title;
          let message;
          let soundType;
          let type;

          if (errorType(errorMessage) === "Already changed") {
            title = "Order handled!";
            message = "It looks like your team has already Handled this order.";
            soundType = "notification";
            type = "success";
          } else {
            title = "Error!";
            message = `Order cant be ${OrderStatusInput.status}.`;
            soundType = "alert";
            type = "danger";
          }

          notification.actions.localNotify({
            title,
            message,
            type,
            soundType,
          });
        });
    } catch (error) {
      notification.actions.localNotify({
        title: "Error!",
        message: `Something went wrong: ${JSON.stringify(error)} `,
        soundType: "alert",
        type: "danger",
      });
    }
  };

  const moveInCompletedOrder = (orderId) => {
    const changeState = state => state.filter((order) => {
      return order._id !== orderId
    });
    setAllOrders(changeState);
    if (viewOrder && viewOrder._id === orderId) {
      setViewOrder(null);
    }
   
    if (viewOrder && viewOrder._id === orderId) {
      setViewOrder(null);
    }
  };

  const setOrderDeliveryOption = ({ orderId, deliveryVendor }) => {
    if (allOrders && allOrders.length) {
      setAllOrders((state) => {
        return state.map((order) => {
          if (order._id === orderId) {
            return { ...order, vendor: deliveryVendor };
          }
          return order;
        });
      });
    }
  };

  const value = {
    allOrders,
    viewOrder,
    deliveryVendorList,
    storeData: storeData,
    multipleOutlet,
    actions: {
      onChangeOrderStatus,
      setViewOrder,
      setOrderDeliveryOption,
      setMultipleOutlet,
      setSelectedOutlets,
      setRefresh,
    },
    orderModal,
    hideOrderModal
  };

  return <Context.Provider value={value}>{props.children}
  </Context.Provider>;

};

export default Provider;
