import React, { Component } from "react";
import firebase, { messaging, auth } from "firebase";
import { REGISTER_TOKEN } from "../components/GraphQL";
import Sound from "react-sound";
import alert from '../assets/sounds/alert.mp3';
import newOrder from '../assets/sounds/new-order.mp3';
import notification from '../assets/sounds/notification.mp3';
import { store } from 'react-notifications-component';
import { isInStandaloneMode } from '../components/Helpers';
import { nanoid } from 'nanoid';
import { registerServiceWorker } from '../serviceWorker';

export const Context = React.createContext();

const sound = {
  newOrder: newOrder,
  notification: notification,
  alert: alert,
}

export class Provider extends Component {
  constructor() {
    super();
    window.soundManager.setup({ debugMode: false });
  }
  
  state = {
    soundState: Sound.status.STOPPED,
    notificationSound: sound.notification,
    isTabFocused: true,
    orderSoundStatus: Sound.status.STOPPED,
    orderSoundLoop: false,
    didUserInteract: false,
  };

  onWindowInteracted = () => {
    if(!this.state.didUserInteract){
      this.setState({didUserInteract:true})
    }
  }

  componentDidMount() {
  
    this.listener = auth().onAuthStateChanged((authUser) => {
      if (authUser && firebase.messaging.isSupported()) {
        this.requestNotificationPermission();
      }
    });

    if(firebase.messaging.isSupported()){
      firebase.messaging().onMessage((payload) => {
        if (payload?.data.action === "orderCancelled" && (this.state.didUserInteract || isInStandaloneMode() )) {
          this.setState({
            soundState: Sound.status.PLAYING,
            notificationSound: sound.alert,
          });
        }
      });
    }
    window.addEventListener("focus", this.onFocus);
    window.addEventListener("blur", this.onBlur);
    window.addEventListener('click', this.onWindowInteracted)

  }

  componentWillUnmount() {
    this.listener();
    window.removeEventListener("focus", this.onFocus);
    window.removeEventListener("blur", this.onBlur);
    window.removeEventListener('click', this.onWindowInteracted);

  }

  render() {
    const value = {
      ...this.state,
      actions: {
        localNotify: this.localNotify,
        dismissLocalNotify: this.dismissLocalNotify,
        playOrderSound: this.playOrderSound,
        stopOrderSound: this.stopOrderSound,
      },
    };

    return (
      <Context.Provider value={value}>
        <Sound
          volume={100}
          url={this.state.notificationSound}
          playStatus={this.state.soundState}
          onFinishedPlaying={this.handleSongFinishedPlaying}
        />

        <Sound
          volume={100}
          loop={this.state.orderSoundLoop}
          url={sound.newOrder}
          playStatus={this.state.orderSoundStatus}
        />
        {this.props.children}
      </Context.Provider>
    );
  }

  requestNotificationPermission = async () => {
    try {
      const res = await Notification.requestPermission();
      if (res === "granted") {
        this.handlePermissionGranted();
      }
    } catch (error) {}
  };

  handlePermissionGranted = async () => {
    try {
      const item = window.localStorage.getItem("FCMT");
      const serviceWorkerRegistration = await registerServiceWorker();
      if(serviceWorkerRegistration){
        const vapidKey = process.env.REACT_APP_VAPIKEY;
        const token = await messaging().getToken({
          vapidKey,
          serviceWorkerRegistration,
        });      
     
        if (!item || JSON.parse(item) !== token) {
          await this.props.client.mutate({
            mutation: REGISTER_TOKEN,
            variables: { fcmToken: token },
            context: {
              headers:{
                authorization: await auth().currentUser.getIdToken()
              }
            }
          });
  
          window.localStorage.setItem("FCMT", JSON.stringify(token));
        }
      }
    } catch (error) {
      if(error.code !== 'messaging/failed-service-worker-registration'){
        this.localNotify({
          title: 'Error!', 
          message: `Failed while subscribing to notifications: ${JSON.stringify(error)}`, 
          type: 'danger', 
          soundType: 'alert',
        });
      }
    }
  };

  handleSongFinishedPlaying = () => {
    this.setState({ soundState: Sound.status.STOPPED });
  };

  playOrderSound = () => {
    if(this.state.didUserInteract || isInStandaloneMode() ){
      this.setState({ 
        orderSoundStatus: Sound.status.PLAYING,
        orderSoundLoop: true,
      });
    }
  }

  stopOrderSound = (args) => {
    if(this.state.didUserInteract || isInStandaloneMode() ){
      this.setState({
        orderSoundStatus: Sound.status.STOPPED,
        orderSoundLoop: false,
      });
    }
  }

  localNotify = (args) => {
    const { title, message, type = 'success', soundType = 'notification', id = nanoid()} = args;
    if (soundType !== null && (this.state.didUserInteract || isInStandaloneMode() )) {
      this.setState({
        soundState: Sound.status.PLAYING,
        notificationSound: sound[soundType],
      });
    }

    if(title && message){
      store.addNotification({
        id,
        title,
        message,
        type,
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: window.innerWidth > 800 ? 0 : 6000,
          onScreen: true,
          pauseOnHover: true,
          showIcon: true,
        },
      });
    }
  };

  dismissLocalNotify = (id) => {
    store.removeNotification(id);
  }

  onBlur = () => {
    this.setState({ isTabFocused: false });
  };

  onFocus = () => {
    this.setState({ isTabFocused: true });
  };
}

/**
 * A higher-order component that wraps the provided component in a Context Consumer component.
 * @param {class} Component - A React component.
 * @returns {function} A higher-order component.
 */

export default function withContext(Component) {
  return function ContextComponent(props) {
    return (
      <Context.Consumer>
        {(context) => <Component {...props} context={context} />}
      </Context.Consumer>
    );
  };
}
